import Adaptor from './adaptor'
import GenericCTI from './integration.bridge/genericcti'

export default class GenericCtiAdaptor extends Adaptor {
  importVendor () {
    const bridge = new GenericCTI()

    return Promise.resolve(bridge)
  }

  getExternalId (vendor) {
    return vendor.getExternalId(this.sessionInfo)
  }

  onOpenContact (data, vendor, mp, capabilities) {
    vendor.onOpenContact(data, mp, capabilities)
  }

  onSendData (data, vendor, mp, capabilities) {
    vendor.onSendData(data, mp, capabilities)
  }

  onMessageProviderReady (vendor, mp, capabilities) {
    vendor.onClickToCall((message) => {
      this.clickToCall(message, mp)
    })
  }

  clickToCall (message, messageProvider) {
    messageProvider.dialContact(message)
  }

  showCTIPopover (vendor) {
    vendor.showPopover()
  }

  hideCTIPopover (vendor) {
    vendor.hidePopover()
  }

  applyHeight (height, vendor) {
    vendor.applyHeight(height)
  }

  onConnect (data, vendor, mp, capabilities) {
    vendor.onConnect(data, mp, capabilities)
  }

  onDisconnect (data, vendor, mp, capabilities) {
    vendor.onDisconnect(data, mp, capabilities)
  }

  onUserLogoutForced (vendor, mp, capabilities) {
    vendor.onUserLogoutForced(mp, capabilities)
  }

  onAgentStatusChanged (data, vendor, mp, capabilities) {
    vendor.onAgentStatusChanged(data, mp, capabilities)
  }

  onLoginSuccess (data, vendor, mp, capabilities) {
    vendor.onLoginSuccess(data, mp, capabilities)
  }

  onLogin (vendor, mp, capabilities) {
    vendor.onLogin(mp, capabilities)
  }

  onLogout (vendor, mp, capabilities) {
    vendor.onLogout(mp, capabilities)
  }
}
