export default (urlParams) => {
  const params = Object.fromEntries(new URLSearchParams(urlParams))
  const integration = (params.integration || 'salesforce').toLowerCase()
  const frameOrigin = (params.sfdcIframeOrigin || params.sfdcIFrameOrigin || '').toLowerCase()
  // When the CTI is loaded on SFDC lightning, it includes the query param 'mode=lightning'
  const mode = (params.mode || '').toLowerCase()
  const provider = (params.provider || 'CHROME').toUpperCase()
  const namespace = (params.namespace)
  const useGeneric = (params.use_generic || 'false').toLowerCase() === 'true'

  return {
    integration,
    mode,
    frameOrigin,
    provider,
    namespace,
    useGeneric
  }
}

export function getNamespace (urlNamespace, defaultNamespace, separator) {
  let appNamespace = `${defaultNamespace}${separator}`

  if (urlNamespace === '') {
    appNamespace = ''
  } else if (urlNamespace) {
    appNamespace = `${urlNamespace}${separator}`
  }

  return appNamespace
}
