import GenericCtiAdaptor from './genericcti.adaptor'
import Zendesk from './integration.bridge/zendesk'
import { authorizeTalkdesk } from './authorization/authorize.talkdesk'

export default class ZendeskAdaptor extends GenericCtiAdaptor {
  constructor (...args) {
    super('zendesk', ...args)
  }

  importVendor () {
    const bridge = new Zendesk()
    return Promise.resolve(bridge)
  }

  onOpenTicket (data, vendor, mp, capabilities) {
    vendor.onOpenTicket(data, mp, capabilities)
  }

  onFetchRelateOptions (data, vendor, mp, capabilities) {
    return vendor.onFetchRelateOptions(data, mp, capabilities)
  }

  // The methods below will be deleted when relate-to feature migration is finished
  getSubdomain (vendor) {
    return vendor.getSubdomain()
  }

  onAdaptorReady (vendor) {
    this.getSubdomain(vendor).then(accountName => {
      this.accountName = accountName
      authorizeTalkdesk(this.name, this.accountName)
    })
  }
}
