import getEnv from '../config'
import getClient from './splitio'
import {
  DEFAULT_ACTIVE_FF,
  FF_WHITE_LIST,
  FALLBACK_FF_ON_SPLITIO_FAIL,
  CTI_RTM_OVER_PUSHER,
  CTI_CLIENT_AUTOMATIC_ACCOUNT_LOGIN
} from './whitelist'

export const isAccountRtmEnabled = (featureFlags) => featureFlags.includes(CTI_RTM_OVER_PUSHER)
export const isAutomaticAccountLoginEnabled = (featureFlags) =>
  featureFlags.includes(CTI_CLIENT_AUTOMATIC_ACCOUNT_LOGIN)

function processFlags (splitioFlags, features) {
  return features.reduce((result, flag) => {
    if (splitioFlags[flag] === 'on') {
      result.push(flag.toUpperCase())
    }
    return result
  }, [])
}

function getFlags (client, features) {
  return processFlags(client.getTreatments(features), features)
}

function getSplitIoFeatureFlags (trafficKey, prefix) {
  const splitIoEnabled = getEnv('SPLIT_IO_ENABLED')
  const splitIoKey = getEnv('SPLIT_IO_KEY')

  if (!splitIoEnabled || splitIoEnabled !== 'true' || !splitIoKey) { return Promise.resolve(DEFAULT_ACTIVE_FF) }

  return getClient(splitIoKey, trafficKey)
    .then(({ client, manager }) => {
      const flags = getFlags(client, FF_WHITE_LIST)
      client.destroy()
      return flags
    })
    .catch(() => {
      return FALLBACK_FF_ON_SPLITIO_FAIL
    })
}

function getSplitIoCompatibilityEnv (prefix = 'FF_', suffix = '_SPLITIO_COMPATIBILITY') {
  if (window.env && window.env.__AZORES_CTI__) {
    return Object.keys(window.env.__AZORES_CTI__).reduce((envVars, key) => {
      if (key.startsWith(prefix) && key.endsWith(suffix)) {
        const feature = key.slice(prefix.length, key.length - suffix.length).toUpperCase()
        envVars[feature] = window.env.__AZORES_CTI__[key]
      }
      return envVars
    }, {})
  }
  return {}
}

function getEnvFeatureFlags (trafficKey, prefix) {
  const envFlags = getSplitIoCompatibilityEnv()

  return Promise.resolve(
    Object.keys(envFlags).reduce((result, key) => {
      if (
        key.startsWith(prefix) &&
        (envFlags[key] === 'all' || envFlags[key].split(',').includes(trafficKey))
      ) {
        result.push(key)
      }
      return result
    }, [])
  )
}

export const getFeatureFlags = (trafficKey, prefix = 'CTI_') => {
  const fedrampEnv = getEnv('FEDRAMP_ENV')

  if (fedrampEnv === 'true') {
    return getEnvFeatureFlags(trafficKey, prefix)
  }

  return getSplitIoFeatureFlags(trafficKey, prefix)
}

export default getFeatureFlags
