import GenericCTI from './genericcti'

const GET_SUBDOMAIN_RESPONSE = 'getSubdomain_response'

export default class Zendesk extends GenericCTI {
  constructor (...args) {
    super(...args, new Set([
      GET_SUBDOMAIN_RESPONSE
    ]))
  }

  getSubdomain () {
    return new Promise((resolve, reject) => {
      const rejectTimeout = setTimeout(reject, 5000)
      this.events.on(GET_SUBDOMAIN_RESPONSE, (data) => {
        clearTimeout(rejectTimeout)
        resolve(data.response)
      })
      this._sendMessage({
        action: 'getSubdomain'
      })
    })
  }

  onOpenTicket (data) {
    this._sendMessage({
      action: 'openTicket',
      data
    })
  }

  onFetchRelateOptions (data) {
    return new Promise((resolve, reject) => {
      const rejectTimeout = setTimeout(reject, 5000)
      this.events.on('fetchRelateOptions_response', (message) => {
        clearTimeout(rejectTimeout)
        resolve(message.response)
      })
      this._sendMessage({
        action: 'fetchRelateOptions',
        data
      })
    })
  }
}
