import Adaptor from './adaptor'
import SalesforceAdaptor from './salesforce.adaptor'
import LightningAdaptor from './salesforce.lightning.adaptor'
import ZendeskAdaptor from './zendesk.adaptor'
import ZendeskV2Adaptor from './zendesk.v2.adaptor'
import ZendeskV3Adaptor from './zendesk.v3.adaptor'
import ServicenowAdaptor from './servicenow.adaptor'
import GenericCtiAdaptor from './genericcti.adaptor'
import DynamicsAdaptor from './dynamics.adaptor'

const integrationAdaptors = {
  salesforce: SalesforceAdaptor,
  lightning: LightningAdaptor,
  zendesk: ZendeskAdaptor,
  zendeskv2: ZendeskV2Adaptor,
  zendeskv3: ZendeskV3Adaptor,
  servicenow: ServicenowAdaptor,
  dynamics365: DynamicsAdaptor
}

const getAdaptor = (integration, messageProvider, activeFeatures, sessionInfo, hooks, platform, useGeneric) => {
  const IntegrationAdaptor = integrationAdaptors[integration]

  if (useGeneric) {
    return new GenericCtiAdaptor(integration, messageProvider, activeFeatures, sessionInfo, hooks, platform)
  } else {
    return IntegrationAdaptor
      ? new IntegrationAdaptor(messageProvider, activeFeatures, sessionInfo, hooks, platform)
      : new Adaptor('dummy')
  }
}

export default {
  getAdaptor
}
