import VisibilityController from './visibility.controller'

const CONTACT_PREFIX = '003'
const LEAD_PREFIX = '00Q'
const CASE_PREFIX = '500'

const MANAGED_PACKAGE_REQUEST_TIMEOUT = 1000

const newEntityURL = {
  multipleContacts: '_ui/search/ui/UnifiedSearchResults?searchType=2&str=',
  contact: `${CONTACT_PREFIX}/e?con10=`,
  lead: `${LEAD_PREFIX}/e?lea8=`
}

export const compileSalesforceURL = (recordType, phoneNumber) => {
  return newEntityURL[recordType] + window.encodeURIComponent(phoneNumber)
}

export const formatTo18 = (objectId) => {
  if (objectId.length === 18) return objectId

  let s = ''
  for (let i = 0; i < 3; i++) {
    let f = 0
    for (let j = 0; j < 5; j++) {
      const c = objectId.charAt(i * 5 + j)
      if (c >= 'A' && c <= 'Z') f += 1 << j
    }

    s += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345'.charAt(f)
  }

  return objectId + s
}

export const isValidContactId = (objectId, prefixes) => {
  prefixes = prefixes || [LEAD_PREFIX, CONTACT_PREFIX]
  return !!(objectId &&
    prefixes.some((prefix) => objectId.startsWith(prefix)))
}

export const isValidCaseId = (objectId) => {
  return objectId && objectId.startsWith(CASE_PREFIX)
}

export const getContactId = (contact) => {
  let salesforceId = null
  let recordType = contact.recordType || 'contact'

  if (contact && contact.externalId) {
    const multipleContacts = contact.customers && contact.customers.length > 1

    salesforceId = contact.externalId

    if (multipleContacts) {
      recordType = 'multipleContacts'
      salesforceId = compileSalesforceURL(recordType, contact.phoneNumber)
    }
  }

  if (!salesforceId) {
    salesforceId = compileSalesforceURL(recordType, contact.phoneNumber)
  }

  return salesforceId
}

export const request = (requestPromise, timeout = MANAGED_PACKAGE_REQUEST_TIMEOUT) => {
  return Promise.race([
    requestPromise,
    requestTimeout(timeout)
  ])
}

function requestTimeout (timeout) {
  return new Promise((resolve, reject) => {
    setTimeout(reject, timeout, new Error('Apex request timed out.'))
  })
}

export { VisibilityController }
