export const addQueryParam = (queryString) => {
  const baseUrl = window.location.href
  window.history.replaceState({}, '', baseUrl + '&' + queryString)
}

export const removeQueryParam = (key) => {
  const url = new URL(window.location.href)
  url.searchParams.delete(key)
  window.history.replaceState({}, '', url.href)
}

export const getQueryParam = (key) => {
  const urlSearch = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearch.entries())
  return key in params ? params[key] : null
}

export const redirectWithError = (error) => {
  const queryParam = error ? `error=${error}` : ''
  addQueryParam(queryParam)
  window.location.pathname = '/'
}
