export default (userId) => {
  const newRelicInstance = window.NREUM

  if (!newRelicInstance) {
    return {
      reportError: () => {}
    }
  }

  newRelicInstance.setCustomAttribute('talkdeskUserId', userId)

  return {
    reportError: (error, options) => {
      newRelicInstance.noticeError(error, options)
    }
  }
}
