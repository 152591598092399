import { logMessage } from '../../helpers/log'
import retry from 'async/retry'

export class SalesforceConsole {
  constructor (sfConsole, options) {
    this.options = Object.assign({
      numberOfRetries: 1,
      backoffValue: 0
    }, options)

    this.sfConsole = sfConsole
    this.log = logMessage('Salesforce Console')

    this._getServicePresenceStatusId = this._getServicePresenceStatusId.bind(this)
  }

  fireEvent (event, data) {
    const eventName = this._getPresenceEventName(event)

    if (eventName) {
      this.sfConsole.fireEvent(eventName, data)
    }
  }

  onLogout (callback) {
    this._subscribeToConsoleEvent('LOGOUT', callback)
  }

  onPresenceStatusChanged (callback) {
    this._subscribeToConsoleEvent('STATUS_CHANGED', callback)
  }

  onWorkItemAccepted (callback) {
    this._subscribeToConsoleEvent('WORK_ACCEPTED', callback)
  }

  onWorkItemRejected (callback) {
    this._subscribeToConsoleEvent('WORK_DECLINED', callback)
  }

  onWorkloadChangedAndCapacity (callback) {
    this._subscribeToConsoleEvent('WORKLOAD_CHANGED', callback)
  }

  onWorkItemClosed (callback) {
    this._subscribeToConsoleEvent('WORK_CLOSED', callback)
  }

  getAgentWorkload (callback) {
    this.sfConsole.presence.getAgentWorkload(callback)
  }

  getServicePresenceStatusChannels (callback) {
    this.sfConsole.presence.getServicePresenceStatusChannels(callback)
  }

  setServicePresenceStatus (statusId, callback) {
    this.sfConsole.presence.setServicePresenceStatus(statusId, (result) => {
      this.log(result.success ? 'setServicePresenceStatus successful' : 'setServicePresenceStatus failed')
      callback && callback(result)
    })
  }

  logout (callback) {
    this._requestToConsole('logout', callback)
  }

  getServicePresenceStatusId (callback) {
    const options = {
      times: this.options.numberOfRetries,
      interval: (retryCount) => {
        this.log(`getServicePresenceStatusId retry ${retryCount}`)
        return this.options.backoffValue * Math.pow(2, retryCount)
      }
    }

    retry(
      options,
      this._getServicePresenceStatusId,
      (err, result) => {
        if (err) {
          this.log(err.description)
        }

        callback(result)
      }
    )
  }

  _getServicePresenceStatusId (callback) {
    this._requestToConsole('getServicePresenceStatusId', (result) => {
      if (result.success) {
        callback(undefined, result)
      } else {
        callback({ description: 'request failed' }, result)
      }
    })
  }

  _requestToConsole (method, callback) {
    const action = this.sfConsole.presence[method]

    if (action) {
      action((result) => {
        this.log(`${method} ${result.success ? 'successful' : 'failed'}`)
        callback && callback(result)
      })
    }
  }

  _subscribeToConsoleEvent (event, callback) {
    const eventName = this._getPresenceEventName(event)

    if (eventName) {
      this.log(`susbcribe to ${eventName}`)
      this.sfConsole.addEventListener(eventName, callback)
    }
  }

  _getPresenceEventName (eventName) {
    return this.sfConsole.ConsoleEvent.PRESENCE[eventName]
  }
}

export default SalesforceConsole
