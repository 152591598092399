import Adaptor from './adaptor'
import servicenow from '../lib/servicenow'

class ServicenowAdaptor extends Adaptor {
  constructor (...args) {
    super('servicenow', ...args)
  }

  getExternalId (vendor) {
    return new Promise((resolve, reject) => {
      const externalId = vendor.getExternalId()

      if (externalId) {
        resolve(externalId)
      } else {
        reject('ServiceNow Agent External ID not found')
      }
    })
  }

  importVendor () {
    return new Promise((resolve, reject) => {
      if (!servicenow) return reject('Servicenow library not available')

      resolve(servicenow)
    })
  }

  /*
  * Hooks implementation
  */
  showCTIPopover (vendor, height = 250) {
    vendor.showOpenFrame()
  }

  hideCTIPopover (vendor) {
    vendor.hideOpenFrame()
  }

  onOpenResource (data, vendor) {
    vendor.openResource(data)
  }

  onOpenContact (contact, vendor) {
    vendor.openContact(contact)
  }

  onMessageProviderReady (vendor, mp) {
    vendor.login({ accountName: this.sessionInfo.accountName })
    vendor.onClickToCall((message) => {
      this.clickToCall(message, mp)
    })
  }

  clickToCall (message, mp) {
    mp.dialContact(message)
  }

  onFetchRelateOptions (data, vendor) {
    return vendor.fetchRelateOptions(data)
  }
}

export default ServicenowAdaptor
