import React from 'react'
import { PropTypes } from 'prop-types'
import './Notification.scss'
import classnames from 'classnames'

const Notification = ({ message, onCloseNotification, type = 'error' }) => {
  const onDismiss = (e) => {
    onCloseNotification()
  }

  const style = classnames('notification-container', type, { show: message })

  return (
    <div className={style}>
      <span className='message'>{message && message.text}</span>
      <div className='user-actions'>
        <span className='dismiss' onClick={onDismiss}>&#215;</span>
      </div>
    </div>
  )
}

Notification.propTypes = {
  message: PropTypes.object,
  onCloseNotification: PropTypes.func
}

export default Notification
