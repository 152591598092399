import GenericCtiAdaptor from './genericcti.adaptor'
import Dynamics365 from './integration.bridge/dynamics365'

export default class DynamicsAdaptor extends GenericCtiAdaptor {
  constructor (...args) {
    super('dynamics365', ...args)
  }

  importVendor () {
    const bridge = new Dynamics365()
    return new Promise((resolve, reject) => {
      resolve(bridge)
    })
  }

  onOpenTicket (data, vendor, mp, capabilities) {
    vendor.onOpenTicket(data, mp, capabilities)
  }

  onFetchRelateOptions (data, vendor, mp, capabilities) {
    return vendor.onFetchRelateOptions(data, mp, capabilities)
  }
}
