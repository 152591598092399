export const getIntegrationSupportsCallRelating = (vendor) => {
  return fetch(`integrations/${vendor}/options/call-relating`)
    .then(response => {
      if (!response.ok) {
        return null
      }
      return response.json()
    }).then(response => {
      if (response.call_relating !== undefined) {
        return response.call_relating === 'enabled'
      } else {
        return false
      }
    }).catch(() => {
      return false
    })
}
