export default class VisibilityController {
  constructor (checkIsVisible, setVisible) {
    this.notifyCallback = () => {}
    this.setVisible = setVisible
    this.checkIsVisible = checkIsVisible

    this._watchIsVisible(false)
  }

  _watchIsVisible (initialValue) {
    this.isCtiVisible = initialValue
    this.checkInterval = setInterval(() => {
      this.checkIsVisible((isVisible) => {
        if (this.checkInterval && this.isCtiVisible !== isVisible) {
          this.isCtiVisible = isVisible
          this.notifyCallback(isVisible)
        }
      })
    }, 150)
  }

  _changeVisibility (newVisibility) {
    return new Promise((resolve, reject) => {
      this.setVisible(newVisibility, (success) => {
        if (success) {
          const interval = setInterval(() => {
            this.checkIsVisible((isVisible) => {
              if (newVisibility === isVisible) {
                clearInterval(interval)
                resolve(newVisibility)
              }
            })
          }, 100)
        } else {
          reject()
        }
      })
    })
  }

  updateVisibility (newVisibility) {
    if (this.isCtiVisible !== newVisibility) {
      clearInterval(this.checkInterval)
      this.checkInterval = null

      this._changeVisibility(newVisibility)
        .then((visibility) => { this._watchIsVisible(visibility) })
        .catch(() => { this._watchIsVisible(this.isCtiVisible) })
    }
  }

  onVisibilityChange (callback) {
    this.notifyCallback = callback
  }
}
