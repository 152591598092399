import React from 'react'
import { PropTypes } from 'prop-types'
import './Button.scss'

const Button = ({ children, onClick, disabled, className = '' }) => {
  className += ' button'

  return (
    <button onClick={onClick} className={className} disabled={disabled}>
      {children}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default Button
