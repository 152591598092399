import React from 'react'
import ReactDOM from 'react-dom'
import App from './components'

const login = (integration, error, org, account) => {
  ReactDOM.render(
    <App integration={integration} error={error} org={org} account={account} />,
    document.querySelector('#root')
  )
}

export default login
