/* global successfulLogout */

import React, { Component } from 'react'
import '../assets/App.scss'
import Button from './Button'
import Input from './Input'
import Notification from './Notification'
import classnames from 'classnames'
import isDomainName from 'is-domain-name'
import * as errors from './constants'
import { getQueryParam, removeQueryParam } from '../../helpers/query.string'

class App extends Component {
  constructor (props) {
    super(props)

    this.state = {
      logging: false,
      account: props.account,
      error: props.error
    }

    this.handleAuthenticate = this.handleAuthenticate.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.showErrorNotification = this.showErrorNotification.bind(this)
    this.showAccount = this.showAccount.bind(this)
    this.onAccountChange = this.onAccountChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.isValidAccountName = this.isValidAccountName.bind(this)

    window.authenticationCallback = () => {
      clearInterval(this.windowTimer)

      setTimeout(() => {
        window.location.href = '/' + window.location.search
      }, 0)
    }
  }

  componentDidMount () {
    if (this.state.account && !successfulLogout) {
      this.handleAuthenticate()
    }
  }

  showErrorNotification (error) {
    switch (error) {
      case errors.TALKDESK_END_SESSION_UNAUTHORIZED:
        return <Notification message={{ text: 'Failed to end Talkdesk session.' }} onCloseNotification={this.closeNotification} />
      case errors.NO_EXTERNAL_ID:
        return <Notification message={{ text: 'Unexpected error.\n Please refresh page.' }} onCloseNotification={this.closeNotification} />
      case errors.DIFFERENT_EXTERNAL_IDS:
        return <Notification message={{ text: 'Failed to login.\n Make sure to connect to the same agent on both CTI and CRM.' }} onCloseNotification={this.closeNotification} />
      case errors.FAILED_LOGIN:
        return <Notification message={{ text: 'Failed to login' }} onCloseNotification={this.closeNotification} />
      case errors.INVALID_ACCOUNT:
        return <Notification message={{ text: 'Invalid domain name' }} onCloseNotification={this.closeNotification} />
      case errors.USER_LOGOUT_FORCED:
        return <Notification message={{ text: 'You have logged out in another application.' }} type='info' onCloseNotification={this.closeNotification} />
      case errors.MISMATCHED_INTEGRATION_ERROR:
        // eslint-disable-next-line no-case-declarations
        const defaultIntegration = getQueryParam('default_integration')
        removeQueryParam('default_integration')
        return <Notification message={{ text: `Failed to login. Make sure you are using your default integration ${defaultIntegration}.` }} onCloseNotification={this.closeNotification} />
      default:
        return null
    }
  }

  closeNotification () {
    this.setState({
      error: null
    })
  }

  isValidAccountName (name = '') {
    return isDomainName(name)
  }

  showAccount () {
    clearInterval(this.windowTimer)

    if (this.authWindow) {
      this.authWindow.close()
    }
    this.setState({
      logging: false
    })
  }

  onAccountChange (account) {
    this.closeNotification()
    this.setState({
      account
    })
  }

  handleClick () {
    this.handleAuthenticate()
  }

  handleAuthenticate () {
    this.closeNotification()
    if (!this.isValidAccountName(this.state.account)) {
      this.setState({
        error: errors.INVALID_ACCOUNT
      })
      return
    }
    this.setState({
      logging: true
    })

    let authURL = `/auth/authorize?account=${this.state.account}`
    if (this.props.org) {
      authURL = authURL + `&org=${this.props.org}`
    }

    this.authWindow = window.open(
      authURL,
      'auth_window',
      'menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes,centerscreen=true,width=600,height=600'
    )

    clearInterval(this.windowTimer)

    if (this.authWindow) {
      this.windowTimer = setInterval(() => {
        if (this.authWindow.closed) {
          clearInterval(this.windowTimer)
          this.setState({
            error: errors.FAILED_LOGIN
          })
          this.showAccount()
        }
      }, 500)

      this.authWindow.focus()
    }
  }

  render () {
    const title = this.state.logging ? 'Logging in to' : 'Log in'
    const subTitle = this.state.logging ? this.state.account : null
    const inputStyle = classnames({ hide: this.state.logging })
    const integration = this.props.integration
    const appStyle = classnames('app', { [integration]: integration })

    return (
      <div className={appStyle}>
        {this.showErrorNotification(this.state.error)}
        <div className='logo' style={{ background: `url('${__LOGO_URL__}')`, 'background-size': 'cover' }} />
        <div className='title'>
          <p className='title'>{title}</p>
          <p className='account' onClick={this.showAccount}>{subTitle}</p>
        </div>
        <div className='bottom'>
          <div className={inputStyle}>
            <Input value={this.state.account} onChange={this.onAccountChange} onEnter={this.handleAuthenticate} />
          </div>
          {this.state.logging
            ? null
            : <Button onClick={this.handleClick}>
              Login
            </Button>}
        </div>
      </div>
    )
  }
}

export default App
