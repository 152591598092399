import Adaptor from './adaptor'
import { authorizeTalkdesk } from './authorization/authorize.talkdesk'

const ZENDESK_HEADER_HEIGHT = 38

class ZendeskV2Adaptor extends Adaptor {
  constructor (...args) {
    super('zendesk', ...args)
  }

  importVendor () {
    require('../lib/zendesk.v2')
    return new Promise((resolve, reject) => {
      const vendor = ZAFClient.init((context) => {
        vendor.get('currentAccount.subdomain').then((data) => {
          this.accountName = data['currentAccount.subdomain']
          vendor.get('currentUser').then((data) => {
            this.externalId = data.currentUser.id
            resolve(vendor)
          })
        })
      })

      if (!vendor) {
        return reject(new Error(`${this.name} vendor not available`))
      }
    })
  }

  getWindowSize (height) {
    return { width: '210px', height: `${height}px` }
  }

  applyHeight (height, vendor) {
    vendor.invoke('resize', this.getWindowSize(height))
  }

  showCTIPopover (vendor, height = 262) {
    this.applyHeight(height, vendor)

    vendor.invoke('popover', this.getWindowSize(height + ZENDESK_HEADER_HEIGHT))
  }

  hideCTIPopover (vendor) {
    vendor.invoke('popover', 'hide')
  }

  getExternalId (vendor) {
    if (this.externalId) {
      return Promise.resolve(this.externalId)
    }

    return new Promise((resolve, reject) => {
      vendor.on('app.registered', (context) => {
        resolve(context.currentUser.id)
      })
    })
  }

  /*
  * Hooks implementation
  */
  onAdaptorReady (vendor, mp) {
    authorizeTalkdesk(this.name, this.accountName)
    vendor.on('clickToCall', (message) => {
      this.clickToCall(message, mp)
    })
  }

  onOpenContact (contact, vendor) {
    vendor.trigger('open-profile', contact)
  }

  /*
  * Internal API
  */
  clickToCall (message, mp) {
    mp.dialContact(message)
  }
}

export default ZendeskV2Adaptor
