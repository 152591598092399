import EventEmitter from 'events'
import { logMessage } from '../../helpers/log'

export const PROVIDERS_VERSION = {
  PUSHER: 'v1',
  RTM: 'v2'
}

class BaseProvider extends EventEmitter {
  constructor (name, options) {
    if (!name) throw new Error('Provider name is required')
    if (!options || !options.adaptorName) throw new Error('Adaptor name is required')
    super()
    this.name = name
    this.adaptorName = options.adaptorName
    this.externalId = options.externalId
    this.useOmnichannel = options.useOmnichannel
    this.sessionInfo = options.sessionInfo
    this.useSingleSignOut = options.useSingleSignOut
    this.options = options
    this.log = logMessage(`provider-${this.name}`)
    this.messageProviderVersion = name === 'WS' ? PROVIDERS_VERSION.PUSHER : PROVIDERS_VERSION.RTM

    this.log(`Provider Initialize for ${this.adaptorName}`)
  }

  dialContact () {
    this.log('dialContact not implemented')
  }

  ctiVisibility () {
    this.log('ctiVisibility not implemented')
  }

  relateOptions () {
    this.log('relateOptions not implemented')
  }

  onDisconnect () {
    this.log('onDisconnect not implemented')
  }

  onUserLogoutForced () {
    this.log('onUserLogoutForced not implemented')
  }

  onConnect () {
    this.log('onConnect not implemented')
  }

  onLoginSuccess () {
    this.log('onLoginSuccess not implemented')
  }

  integrationStatusChange () {
    this.log('integrationStatusChange not implemented')
  }

  onOpenContact () {
    this.log('onOpenContact not implemented')
  }

  onSendData () {
    this.log('onSendData not implemented')
  }

  onUpdateCallbarVisibility () {
    this.log('onUpdateCallbarVisibility not implemented')
  }

  onRequestInteractionExternalId () {
    this.log('onRequestInteractionExternalId not implemented')
  }

  onOpenTicket () {
    this.log('onOpenTicket not implemented')
  }

  onHvsCallEnd () {
    this.log('onHvsCallEnd not implemented')
  }

  onOpenResource () {
    this.log('onOpenResource not implemented')
  }

  onAgentStatusChanged (callback) {
    this.log('onAgentStatusChanged not implemented')
  }

  onFetchRelateOptions () {
    this.log('onFetchRelateOptions not implemented')
  }

  onRequestRelatedOptionsByChannel () {
    this.log('onRequestRelatedOptionsByChannel not implemented')
  }
}

export default BaseProvider
