import {
  MISMATCHED_INTEGRATION_ERROR
} from '../components/constants'

class CtiError extends Error {
  constructor (message, metadata = {}) {
    super(message)
    this.metadata = metadata
  }
}

export default class MismatchedIntegrationError extends CtiError {
  constructor (defaultIntegration) {
    super(MISMATCHED_INTEGRATION_ERROR, { defaultIntegration })
  }
}
