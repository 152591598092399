import GenericCTI from './genericcti'

export default class Dynamics365 extends GenericCTI {
  onOpenTicket (data) {
    this._sendMessage({
      action: 'openTicket',
      data
    })
  }

  onFetchRelateOptions (data) {
    return new Promise((resolve, reject) => {
      const rejectTimeout = setTimeout(reject, 5000)
      this.events.on('fetchRelateOptions_response', (message) => {
        clearTimeout(rejectTimeout)
        resolve(message.response)
      })
      this._sendMessage({
        action: 'fetchRelateOptions',
        data
      })
    })
  }
}
