import { getQueryParam } from 'src/helpers/query.string.js'
import EventEmitter from 'events'

export const events = new EventEmitter()
const servicenow = {}

export const receiveMessage = (event) => {
  if (!/https:\/\/.*\.service-now\.com/.test(event.origin)) {
    return
  }

  if (event.data && event.data.action) {
    switch (event.data.action) {
      case 'clickToCall':
        events.emit('click-to-call', event.data)
        break
      case 'responseRelatingOptions':
        events.emit('response-relating-options', event.data.options)
        break
      default:
        console.warn('Unsupported action (' + event.data.action + ')')
        break
    }
  } else {
    console.warn('Unknown schema message')
  }
}

window.addEventListener('message', receiveMessage, false)

servicenow.onClickToCall = function (callback) {
  events.on('click-to-call', callback)
}

servicenow.getExternalId = function () {
  return getQueryParam('externalId')
}

servicenow.fetchRelateOptions = function (data) {
  return new Promise((resolve, reject) => {
    const rejectTimeout = setTimeout(reject, 5000)
    events.on('response-relating-options', (data) => {
      clearTimeout(rejectTimeout)
      resolve(data)
    })
    const message = { action: 'fetchRelateOptions', actionData: data }
    window.parent.postMessage(message, '*')
  })
}

servicenow.login = function (data) {
  const message = { action: 'login', actionData: data }
  window.parent.postMessage(message, '*')
}

servicenow.openResource = function (data) {
  const message = { action: 'openResource', actionData: data }
  window.parent.postMessage(message, '*')
}

servicenow.openContact = function (data) {
  const message = { action: 'openContact', actionData: data }
  window.parent.postMessage(message, '*')
}

servicenow.showOpenFrame = function () {
  const message = { action: 'showOpenFrame' }
  window.parent.postMessage(message, '*')
}

servicenow.hideOpenFrame = function () {
  const message = { action: 'hideOpenFrame' }
  window.parent.postMessage(message, '*')
}

export default servicenow
