import { SplitFactory } from '@splitsoftware/splitio'

let factory = null
let manager = null
const clients = {}

export default function getClient (splitIoKey, trafficKey) {
  if (clients[trafficKey]) {
    return Promise.resolve({
      client: clients[trafficKey],
      manager
    })
  }

  const factory = splitFactory(splitIoKey, trafficKey)
  const client = factory.client(trafficKey)

  return new Promise((resolve, reject) => {
    client.on(client.Event.SDK_READY, () => {
      clients[trafficKey] = client
      resolve({
        client, manager
      })
    })

    client.on(client.SDK_READY_TIMED_OUT, () => {
      reject(new Error('sdk_timeout'))
    })
  })
}

function splitFactory (splitIoKey, trafficKey) {
  if (factory === null) {
    factory = SplitFactory({
      core: {
        authorizationKey: splitIoKey,
        key: trafficKey
      },
      startup: {
        retriesOnFailureBeforeReady: 3
      }
    })

    manager = factory.manager()
  }

  return factory
}
