import { PROVIDERS_VERSION } from '../callbar/providers/base.provider'
import {
  CLICK_TO_CALL,
  CLICK_TO_CALL_PUSHER,
  INTEGRATION_STATUS_CHANGED,
  INTEGRATION_STATUS_CHANGED_PUSHER,
  RESPONSE_RELATE_OPTIONS,
  RESPONSE_INTERACTION_EXTERNAL_ID,
  RESPONSE_INTERACTION_EXTERNAL_ID_PUSHER,
  RESPONSE_RELATED_OPTIONS_BY_CHANNEL_PUSHER,
  RESPONSE_RELATING_OPTIONS
} from '../callbar/providers/constants'
import { fetchCtiClient, fetchApiGateway, sendToCtiClient } from './helpers/fetchApi'

export const clickToCall = ({
  messageProviderVersion,
  contact,
  agent,
  topic,
  scope,
  userId,
  apiGatewayUrl,
  headers = {},
  useGateway = false
}) => {
  const requestHeaders = {
    ...headers,
    'Content-Type': 'application/json'
  }

  const api = useGateway ? fetchApiGateway : fetchCtiClient

  const payload = {
    external_id: contact.externalId,
    name: contact.name,
    integration: agent.integration,
    interaction_external_id: contact.interactionExternalId,
    number: contact.number
  }

  if (contact.outbound_caller_id) payload.outbound_caller_id = contact.outbound_caller_id

  if (
    contact.metadata &&
    contact.metadata.constructor === Object &&
    Object.keys(contact.metadata).length > 0
  ) {
    payload.metadata = contact.metadata
  }

  let data

  if (checkRtmUsage(messageProviderVersion)) {
    data = serializePayloadRtmVersion(topic, scope, CLICK_TO_CALL, userId, payload)
  } else {
    data = serializePayloadPusherVersion(CLICK_TO_CALL_PUSHER, agent, payload)
  }

  return api(
    { 'Accept-Version': messageProviderVersion },
    { data, apiGatewayUrl, endpoint: '/cti/message', headers: requestHeaders }
  )
}

export const isCallbarConnected = (integration, externalId) => {
  return fetchCtiClient(
    {},
    {
      endpoint: `/cti/callbar/status?integration=${integration}&external_id=${externalId}`,
      method: 'GET'
    }
  ).then((data) => ({ online: data.is_open }))
}

export const getUserIntegrationExternalId = (integrationName) => {
  return fetchCtiClient(
    {},
    { endpoint: `/integrations/${integrationName}/external_id`, method: 'GET' }
  ).then((data) => {
    if (data && data.external_id) {
      return data.external_id
    }
    throw new Error("Unable to fetch the agent's integration external id")
  })
}

export const sendInteractionExternalId = (
  messageProviderVersion,
  caseId,
  agent,
  reason,
  topic,
  scope,
  userId
) => {
  const payload = {
    success: !!caseId
  }

  if (payload.success) {
    payload.case_id = caseId
  } else {
    payload.reason = reason
  }

  let data

  if (checkRtmUsage(messageProviderVersion)) {
    data = serializePayloadRtmVersion(
      topic,
      scope,
      RESPONSE_INTERACTION_EXTERNAL_ID,
      userId,
      payload
    )
  } else {
    data = serializePayloadPusherVersion(RESPONSE_INTERACTION_EXTERNAL_ID_PUSHER, agent, payload)
  }

  return fetchCtiClient({ 'Accept-Version': messageProviderVersion }, { data })
}

export const sendRelateOptions = (
  messageProviderVersion,
  options,
  agent,
  reason,
  topic,
  scope,
  userId
) => {
  const payload = {
    success: !!options
  }

  if (payload.success) {
    payload.options = options
  } else {
    payload.reason = reason
  }

  let data

  if (checkRtmUsage(messageProviderVersion)) {
    data = serializePayloadRtmVersion(topic, scope, RESPONSE_RELATE_OPTIONS, userId, payload)
  } else {
    data = serializePayloadPusherVersion(RESPONSE_RELATING_OPTIONS, agent, payload)
  }

  return fetchCtiClient({ 'Accept-Version': messageProviderVersion }, { data })
}

export const sendRelatedOptionsByChannel = (response, agent) => {
  const data = serializePayloadPusherVersion(
    RESPONSE_RELATED_OPTIONS_BY_CHANNEL_PUSHER,
    agent,
    response
  )
  return sendToCtiClient({ data })
}

export const isCallbarOpen = (headers = {}, useGateway = false, apiGatewayUrl) => {
  const api = useGateway ? fetchApiGateway : fetchCtiClient

  return new Promise((resolve, reject) => {
    api({ headers, apiGatewayUrl, endpoint: '/callbar/cti/status', method: 'get' })
      .then((response) => {
        resolve(response.is_open)
      })
      .catch(reject)
  })
}

export const sendIntegrationStatusChange = (
  messageProviderVersion,
  status,
  agent,
  topic,
  scope,
  userId
) => {
  const payload = { status }

  let data

  if (checkRtmUsage(messageProviderVersion)) {
    data = serializePayloadRtmVersion(topic, scope, INTEGRATION_STATUS_CHANGED, userId, payload)
  } else {
    data = serializePayloadPusherVersion(INTEGRATION_STATUS_CHANGED_PUSHER, agent, payload)
  }

  return fetchCtiClient({ 'Accept-Version': messageProviderVersion }, { data })
}

export const sendRtmEvent = (topic, scope, userId, event, payload) => {
  const data = serializePayloadRtmVersion(topic, scope, event, userId, payload)
  return fetchCtiClient({ 'Accept-Version': PROVIDERS_VERSION.RTM }, { data })
}

function serializePayloadPusherVersion (type, agent, payload = {}) {
  return {
    type,
    payload,
    user: {
      external_id: agent.externalId,
      integration: agent.integration
    }
  }
}

function checkRtmUsage (messageProviderVersion) {
  return messageProviderVersion === PROVIDERS_VERSION.RTM
}

function serializePayloadRtmVersion (topic, scope, eventName, userId, payload = {}) {
  return {
    topic,
    scope,
    event_name: eventName,
    user_id: userId,
    payload
  }
}

export function getUserDefaultIntegration () {
  return fetchCtiClient({}, { endpoint: '/cti/callbar/user', method: 'GET' }).then(
    (response) => {
      if (response && response.default_cti_integration) {
        return response.default_cti_integration.toLowerCase()
      }

      return undefined
    }
  )
}
