/* eslint-disable no-undef */
const apiGatewayRegions = {
  'cti-client.talkdeskapp.com': 'https://api.talkdeskapp.com',
  'cti-client.talkdeskapp-pubsec.com': 'https://api.talkdeskapp-pubsec.com',
  'cti-client.talkdeskapp.eu': 'https://api.talkdeskapp.eu',
  'cti-client.talkdeskappca.com': 'https://api.talkdeskapp.ca'
}

export const getApiGwEndpoint = () => {
  return apiGatewayRegions[self.location.host] || __API_GATEWAY_ENDPOINT__
}

const getEnv = (key, defaultValue = undefined) => {
  if (typeof window !== 'undefined' && window.env && window.env.__AZORES_CTI__ && key in window.env.__AZORES_CTI__) {
    return window.env.__AZORES_CTI__[key]
  }
  return defaultValue
}

export default getEnv
