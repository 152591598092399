import { getIntegrationSupportsCallRelating } from '../integration.options/supports.call.relating'
import { hasUserAuthorization, askUserAuthorization } from './requests'

export const authorizeTalkdesk = (vendor, vendorAccount) => {
  return getIntegrationSupportsCallRelating(vendor).then(supportsCallRelating => {
    if (supportsCallRelating) {
      return hasUserAuthorization(vendor).then(hasUserAuthorization => {
        if (!hasUserAuthorization) {
          askUserAuthorization(vendor, vendorAccount)
        }
      })
    }
  })
}
