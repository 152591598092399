export const hasUserAuthorization = (vendor) => {
  return fetch(`integrations/${vendor}/oauth/user-authorization`)
    .then(response => {
      if (!response.ok) {
        return null
      }
      return response.json()
    }).then(authorizationId => {
      return authorizationId != null
    }).catch(() => {
      return false
    })
}

export const askUserAuthorization = (vendor, vendorAccount) => {
  return fetch(`integrations/${vendor}/oauth/authorization?vendor_account=${vendorAccount}`)
    .then(response => {
      return response.json()
    }).then(body => {
      const location = JSON.parse(body).location
      window.open(location, '_blank', 'width=1000, height=500')
    })
}
