import input, { getNamespace } from '../../input'

const appNamespace = getNamespace(input(window.location.search).namespace, 'talkdesk', '.')

export const ResponseType = {
  TEXT: 'TEXT',
  JSON: 'JSON'
}

export const jsonRequestParams = (data) => ({
  responseType: ResponseType.JSON,
  cache: false,
  ...data
})

export const textRequestParams = (data) => ({
  responseType: ResponseType.TEXT,
  cache: false,
  ...data
})

export const apexCalls = {
  getKey (args) {
    return Object.keys(args).map(key => args[key]).join('_')
  },
  setValue (key, value) {
    this.cache[key] = value
  },
  getValue (key) {
    return this.cache[key]
  },
  cache: {}
}

export const processApexResult = ({ responseType, result, cache, keyCache }) => {
  const value = responseType === ResponseType.JSON
    ? JSON.parse(result)
    : result

  if (cache) {
    apexCalls.setValue(keyCache, value)
  }

  return value
}

export const apexClassTemplate = template => namespace => template.replace(/__NAMESPACE__./, namespace)

export const interactionApexCall = (data) => {
  const { vendor, args, resolve, reject, keyCache } = data
  const { apexClass, methodName, methodParams, responseType, cache } = args

  const templatedApexClass = apexClassTemplate(apexClass)(appNamespace)

  vendor.interaction.runApex(templatedApexClass, methodName, methodParams, (response) => {
    if (response.error) {
      return reject(new Error(response.error))
    }

    const value = processApexResult({
      responseType,
      result: response.result,
      cache,
      keyCache
    })

    resolve(value)
  })
}

export const openctiApexCall = (data) => {
  const { vendor, args, resolve, reject, keyCache } = data
  const { apexClass, methodName, methodParams, responseType, cache } = args

  const templatedApexClass = apexClassTemplate(apexClass)(appNamespace)

  vendor.opencti.runApex({
    apexClass: templatedApexClass,
    methodName,
    methodParams,
    callback: (response) => {
      if (!response.success) {
        const error = response.errors[0]

        return reject(new Error(error.description))
      }

      const value = processApexResult({
        responseType,
        result: response.returnValue.runApex,
        cache,
        keyCache
      })

      resolve(value)
    }
  })
}

export const processApexCall = (data) => {
  const { vendor, reject } = data

  if (vendor.interaction) {
    interactionApexCall(data)
    return
  }

  if (vendor.opencti) {
    openctiApexCall(data)
    return
  }

  reject(new Error('Apex not supported'))
}

const getApex = (vendor) => (args) => {
  return new Promise((resolve, reject) => {
    const keyCache = apexCalls.getKey(args)

    if (args.cache) {
      const value = apexCalls.getValue(keyCache)
      if (value) {
        return resolve(value)
      }
    }

    processApexCall({
      vendor,
      args,
      resolve,
      reject,
      keyCache
    })
  })
}

export default getApex
